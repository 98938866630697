<template>
    <div>
        <div class="row">
            <div class="col-6" style="color: white;">

                <p style="font-weight: bold; text-decoration: underline;">Personal Details</p>
                <p><strong>Name:</strong><br/>{{ data.salutation }} {{ data.first_name }} {{ data.last_name }}</p>

                <p><strong>Address:</strong><br/>{{ data.street }}<br/>{{ data.town }}<br/>{{data.county}}<br/>{{data.postcode}}</p>

                <p><strong>Email:</strong><br/>{{data.email}}</p>

                <p style="font-weight: bold; text-decoration: underline;">Appeal</p>

                <p><strong>Appellent Type:</strong><br/>{{ data.apellent_type }}</p>

                <p><strong>Message:</strong><br/>{{data.message}}</p>

                <p v-if="data.driving !== null"><strong>Was I the driver at the time of the event?</strong><br><span :class="{'text-danger': data.driving == 0, 'text-success': data.driving == 1}">{{ data.driving == 1 ? 'Yes' : 'No' }}</span></p>

                <div v-if="data.transfer_first_name !== null || data.transfer_last_name !== null || data.transfer_street !== null || data.transfer_postcode !== null">
                    <p style="font-weight: bold; text-decoration: underline;">Transfer Details</p>
                    <p><strong>Transfer Name:</strong><br/>{{ data.transfer_first_name }} {{ data.transfer_last_name }}</p>
                    <p><strong>Transfer Address:</strong><br/>{{ data.transfer_street }}<br/>{{ data.transfer_town }}<br/>{{data.transfer_county}}<br/>{{data.transfer_postcode}}</p>
                </div>

                <div v-if="appeal.status === 'Pending' && $can('claims-process')">

                    <p style="font-weight: bold; text-decoration: underline;">Actions</p>

                    <p>Once you have read the claim carefully, you can either reject or accept the appeal. If you
                        choose to reject the appeal, you must provide a valid reason for rejecting it.</p>
        
                    <p><span class="badge badge-danger">WARNING: </span> The reason provided will be posted directly to the customer.</p>

                    <div class="form-group">
                        <label class="form-label">Select a Template</label>
                        <select class="form-control" @change="templateSelected" v-model="selectedTemplate">
                            <option value="">Select a Template</option>
                            <option v-for="template in templates" :value="template.template">{{template.name}}</option>
                        </select>
                    </div>
        
                    <textarea v-model="rejectionReason" rows="5" class="form-control form-control-dark" placeholder="Outline the reason you rejected the claim..."></textarea>
                    <br/>
                    <submit-button :background="'btn btn-sm btn-danger mg-r-20 mg-b-20'" :clicked="submitting" @click="rejectAppeal" :text="'Reject Appeal'"></submit-button> <submit-button :background="'btn btn-sm btn-success mg-r-20 mg-b-20'" :clicked="accepting" @click="acceptAppeal" :text="'Accept Appeal'"></submit-button>
                    <submit-button v-if="data.transfer_first_name !== null || data.transfer_last_name !== null || data.transfer_street !== null || data.transfer_postcode !== null" :background="'btn btn-sm btn-primary mg-r-20 mg-b-20'" :clicked="submitting" :text="'Accept Appeal & Transfer Liability'" @click="openAcceptAndTransfer"></submit-button>

                    <submit-button :background="'btn btn-sm btn-primary mg-r-20 mg-b-20'" :clicked="submitting" :text="'Transfer Liability to Appellant'" @click="transferOnly"></submit-button>

                    <submit-button :background="'btn btn-sm btn-warning mg-r-20 mg-b-20'" :clicked="submitting" :text="'Accept Appeal & Don\'t Process'" @click="acceptDontProcess"></submit-button> <submit-button :background="'btn btn-sm btn-warning mg-r-20  mg-b-20'" :clicked="submitting" :text="'Reject Appeal & Don\'t Process'" @click="rejectDontProcess"></submit-button>
                </div>
                <div v-if="appeal.status !== 'Pending'">
                    <p v-if="(appeal.reason || '').length > 0"><strong>Rejection Reason: </strong> {{appeal.reason}}</p>
                    <p v-if="claim.popla_code !== null && claim.type == 'parking'"><strong>POPLA Code: </strong> {{claim.popla_code}}</p>
                </div>
        
                <button class="btn btn-back pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12" @click="$emit('back')">Back to Appeals</button>
                
            </div>
            <div class="col-6" style="color: white;">
                
                <p style="font-weight: bold; text-decoration: underline;">Appeal Assets</p>

                <div v-if="assets.length > 0">
                    <div v-for="asset in assets" class="row">
                        <div class="col-6">
                            <p>{{asset.filename}}</p>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-primary btn-sm" @click="downloadAsset(asset)">View</button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>No Assets</p>
                </div>

                <iframe v-if="viewUrl !== null" :src="viewUrl" width="100%" height="1000px"></iframe>

            </div>


            <div :id="'transferModal-' + claim.id" class="modal fade">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content tx-size-sm">
                        <div class="modal-header pd-x-20">
                            <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">Review Transfer Details</h6>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body pd-20">

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">First Name <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" v-model="data.transfer_first_name">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">Last Name <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" v-model="data.transfer_last_name">
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label class="form-label">Street <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="data.transfer_street">
                            </div>

                            <div class="form-group">
                                <label class="form-label">Town <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="data.transfer_town">
                            </div>

                            <div class="form-group">
                                <label class="form-label">County</label>
                                <input type="text" class="form-control" v-model="data.transfer_county">
                            </div>

                            <div class="form-group">
                                <label class="form-label">Postcode <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="data.transfer_postcode">
                            </div>

                            <div class="form-group">
                                <label class="form-label">Type <span class="text-danger">*</span></label>
                                <select class="form-control" v-model="data.transfer_type">
                                    <option value="Keeper">Keeper</option>
                                    <option value="Driver">Driver</option>
                                    <option value="Hirer">Hirer</option>
                                </select>
                            </div>

                        </div><!-- modal-body -->
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary tx-size-xs" @click="acceptAndTransfer">Save changes</button>
                            <button type="button" :ref="'closeTransferModal' + claim.id" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div><!-- modal-dialog -->
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import SubmitButton from '@/components/SubmitButton';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';

export default {
    name: 'ClaimAppeal',
    components: {
        SubmitButton
    },
    props: {
        appeal: {
            type: Object,
            required: true
        },
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            data: {
                id: null,
                claim_id: null,
                client_id: null,
                first_name: null,
                last_name: null,
                salutation: null,
                street: null,
                town: null,
                county: null,
                postcode: null,
                email: null,
                message: null,
                explanation: null,
                status: null,
                created_at: null,
                apellent_type: null,
                transfer_first_name: null,
                transfer_last_name: null,
                transfer_street: null,
                transfer_town: null,
                transfer_county: null,
                transfer_postcode: null,
                transfer_type: null,
                driving: null
            },
            assets: [],
            viewUrl: null,
            rejectionReason: '',
            submitting: false,
            accepting: false,
            templates: [],
            selectedTemplate: null
        }
    },
    mounted(){
        this.getAppeal();
        this.getAppealTemplates();
    },
    beforeUnmount(){
        if(this.viewUrl){
            URL.revokeObjectURL(this.viewUrl);
        }
    },
    methods: {
        getAppeal(){
            axios.get(`https://api.varsanpr.com/api/appeals/${this.appeal.id}?client_id=${this.client_id}&claim_id=${this.claim.id}`, {
                headers: authHeader()
            })
                .then(res => {
                    this.data = res.data.appeal;
                    this.getAssets();
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                });
        },
        getAssets(){
            axios.get(`https://api.varsanpr.com/api/appeals/${this.appeal.id}/assets?claim_id=${this.claim.id}&client_id=${this.client_id}`, {
                headers: authHeader()
            })
                .then(res => {
                    this.assets = res.data.assets;
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                });
        },
        downloadAsset(asset){
            if(this.viewUrl){
                URL.revokeObjectURL(this.viewUrl);
            }
            axios.get(`https://api.varsanpr.com/api/appeals/${this.appeal.id}/assets/${asset.filename}?claim_id=${this.claim.id}&client_id=${this.client_id}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
                .then(res => {
                    this.viewUrl = URL.createObjectURL(new Blob([res.data], {type: res.headers['content-type']}));  
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                });
        },
        async rejectAppeal(){
            if(this.submitting) return;
            this.submitting = true;
            axios.post(`https://api.varsanpr.com/api/appeals/${this.appeal.id}/reject?claim_id=${this.claim.id}&client_id=${this.client_id}`, {
                reason: this.rejectionReason
            }, {
                headers: authHeader()
            })
                .then(res => {
                    if(res.data.success){
                        this.$success('Appeal Rejected');
                        this.$emit('changeMade');
                        this.$emit('back');
                    }else{
                        this.$error(res.data.message);
                    }
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                })
                .finally(() => {
                    this.submitting = false;
                });
        },
        acceptAppeal(){
            if(this.accepting) return
            this.accepting = true;
            axios.post(`https://api.varsanpr.com/api/appeals/${this.appeal.id}/accept?claim_id=${this.claim.id}&client_id=${this.client_id}`, {
                
            }, {
                headers: authHeader()
            })
                .then(res => {
                    if(res.data.success){
                        this.$success('Appeal Accepted');
                        this.$emit('changeMade');
                        this.$emit('back');
                    }else{
                        this.$error(res.data.message);
                    }
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                })
                .finally(() => {
                    this.accepting = false;
                });
        },
        openAcceptAndTransfer(){
            $(`#transferModal-${this.claim.id}`).modal('show');
        },
        acceptAndTransfer(){
            if(this.transferring) return;
            this.transferring = true;
            axios.post(`https://api.varsanpr.com/api/appeals/${this.appeal.id}/transfer?claim_id=${this.claim.id}&client_id=${this.client_id}`, {
                first_name: this.data.transfer_first_name,
                last_name: this.data.transfer_last_name,
                street: this.data.transfer_street,
                town: this.data.transfer_town,
                county: this.data.transfer_county,
                postcode: this.data.transfer_postcode,
                type: this.data.transfer_type
            }, {
                headers: authHeader()
            })
                .then(res => {
                    if(res.data.success){
                        this.$success('Appeal Accepted and Transferred');
                        this.$refs['closeTransferModal' + this.claim.id].click();
                        this.$emit('changeMade');
                        this.$emit('back');
                    }else{
                        this.$error(res.data.message);
                    }
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                })
                .finally(() => {
                    this.transferring = false;
                });

        },
        transferOnly(){
            if(this.transferring) return;
            this.transferring = true;
            axios.post(`https://api.varsanpr.com/api/appeals/${this.appeal.id}/transferonly?claim_id=${this.claim.id}&client_id=${this.client_id}`, {
                
            }, {
                headers: authHeader()
            })
                .then(res => {
                    if(res.data.success){
                        this.$success('Liability Transferred');
                        this.$emit('changeMade');
                        this.$emit('back');
                    }else{
                        this.$error(res.data.message);
                    }
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                })
                .finally(() => {
                    this.transferring = false;
                });
        },
        async acceptDontProcess(){
            if(this.accepting) return

            // Get reason for not processing
            const {value: reason} = await Swal.fire({
                title: `Please explain why you don't want to process this acceptance as normal?`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });

            if(!reason){
                this.$error('You must provide a reason');
                return;
            }

            this.accepting = true;
            axios.post(`https://api.varsanpr.com/api/appeals/${this.appeal.id}/accept`, {
                
            }, {
                headers: authHeader(),
                params: {
                    claim_id: this.claim.id,
                    client_id: this.client_id,
                    preventProcess: true,
                    reason: reason
                }
            })
                .then(res => {
                    if(res.data.success){
                        this.$success('Appeal Accepted');
                        this.$emit('changeMade');
                        this.$emit('back');
                    }else{
                        this.$error(res.data.message);
                    }
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                })
                .finally(() => {
                    this.accepting = false;
                });
        },
        async rejectDontProcess(){
            if(this.submitting) return;

            const {value: reason} = await Swal.fire({
                title: `Please explain why you don't want to process this rejection as normal?`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });

            if(!reason){
                this.$error('You must provide a reason');
                return;
            }

            this.submitting = true;
            axios.post(`https://api.varsanpr.com/api/appeals/${this.appeal.id}/reject`, {
                reason: reason,
                preventProcess: true
            }, {
                headers: authHeader(),
                params: {
                    claim_id: this.claim.id,
                    client_id: this.client_id
                }
            })
                .then(res => {
                    if(res.data.success){
                        this.$success('Appeal Rejected');
                        this.$emit('changeMade');
                        this.$emit('back');
                    }else{
                        this.$error(res.data.message);
                    }
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                })
                .finally(() => {
                    this.submitting = false;
                });
        },
        getAppealTemplates(){
            axios.get(`https://api.varsanpr.com/api/appeals/templates`, {
                headers: authHeader()
            })
                .then(res => {
                    this.templates = res.data.data;
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                });
        },
        templateSelected(){
            console.log(`Template selected`);
            if(this.selectedTemplate){
                let rejectionReason = this.selectedTemplate;
                if(rejectionReason){

                    // Replace placeholders
                    rejectionReason = rejectionReason.replace(/{{reference}}/g, this.claim.reference);
                    rejectionReason = rejectionReason.replace(/{{incident_date}}/g, this.incident_date);
                    rejectionReason = rejectionReason.replace(/{{incident_time}}/g, this.incident_time);
                    rejectionReason = rejectionReason.replace(/{{maximum_stay}}/g, this.maximum_stay);
                    rejectionReason = rejectionReason.replace(/{{stay_duration}}/g, this.stay_duration);

                    this.rejectionReason = rejectionReason;
                }
            }
        }
    },
    computed: {
        incident_date: function (){
            if(this.claim.type !== 'parking'){
                if(this.claim.events[0] !== undefined){
                    return DateTime.fromSeconds(parseInt(this.claim.events[0].created_at)).toFormat("dd/MM/yyyy")
                }
                if(this.claim.incident_timestamp !== null){
                    return DateTime.fromSeconds(parseInt(this.claim.incident_timestamp)).toFormat("dd/MM/yyyy")
                }else{
                    return DateTime.fromSeconds(parseInt(this.claim.date_received)).toFormat("dd/MM/yyyy")
                }

            }else{
                return DateTime.fromSeconds(parseInt(this.claim.events[1].created_at)).toFormat("dd/MM/yyyy")
            }
        },
        incident_time: function (){
            if(this.claim.type !== 'parking'){
                if(this.claim.events[0] !== undefined){
                    return DateTime.fromSeconds(parseInt(this.claim.events[0].created_at)).toFormat("HH:mm:ss")
                }
                if(this.claim.incident_timestamp !== null){
                    return DateTime.fromSeconds(parseInt(this.claim.incident_timestamp)).toFormat("HH:mm:ss")
                }else{
                    return DateTime.fromSeconds(parseInt(this.claim.date_received)).toFormat("HH:mm:ss")
                }

            }else{
                return DateTime.fromSeconds(parseInt(this.claim.events[1].created_at)).toFormat("HH:mm:ss")
            }
        },
        maximum_stay: function(){
            if(this.claim.max_stay){

                // convert number of minutes into hours and minutes
                let hours = Math.floor(this.claim.max_stay / 60);
                let minutes = this.claim.max_stay % 60;

                return `${hours} Hour${hours > 1 ? 's' : ''} ${minutes} Minute${minutes > 1 ? 's' : ''}`;

            } else {
                // use site max stay
                this.claim.site.max_stay = parseInt(this.claim.site.max_stay);
                let hours = Math.floor(this.claim.site.max_stay / 60);
                let minutes = this.claim.site.max_stay % 60;

                return `${hours} Hour${hours > 1 ? 's' : ''} ${minutes} Minute${minutes > 1 ? 's' : ''}`;
            }
        },
        stay_duration: function(){
            if(this.claim.type === 'parking'){
                // get difference between first and last event in hours and minutes
                let first = this.claim.events[0].created_at;
                let last = this.claim.events[this.claim.events.length - 1].created_at;

                let duration = last - first;

                let hours = Math.floor(duration / 3600);
                let minutes = Math.floor((duration % 3600) / 60);

                return `${hours} Hour${hours > 1 ? 's' : ''} ${minutes} Minute${minutes > 1 ? 's' : ''}`;
            }else {
                return "";
            }
        }
    }
}
</script>