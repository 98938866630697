<template>
  <div style="width: 100%;">

      <!-- STEP 1 - Vehicle Image & STEP 2 - MMC -->
      <div v-show="step === 0 || step === 1" class="row">

        <div class="col-6">
            <div v-show="!pixelating">
                <secure-image v-viewer v-if="vehicle_image !== null" :id="'dvlareview_' + claim.id + 'vi'" :filename="vehicle_image.filename" :site_id="claim.site.id" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :client_id="client_id" :storage_account="vehicle_image.storage_account" style="max-height: 75vh; width: auto; max-width: 100%; height: auto;" height="auto"
                    :class="{'rotate-90': currentRotation == 90, 'rotate-180': currentRotation == 180, 'rotate-270': currentRotation == 270}">

                <p> <i class="fa-regular fa-triangle-exclamation text-danger"></i> There was an issue loading the image.</p>

                </secure-image>
            </div>
            <div v-show="pixelating" style="max-height: 75vh; width: auto; max-width: 100%; height: auto;">

                <canvas id="c" width="750" height="500"></canvas>

            </div>

            <div v-if="vehicle_image !== null">
                <button class="btn btn-primary btn-sm" @click="rotateImage">Rotate</button>
                <button v-if="vehicle_image != null && vehicle_image_type !== 'pdf' && !cropper && !pixelating" class="btn btn-sm btn-primary" @click="editImage">Edit</button>
                <button v-if="vehicle_image != null && vehicle_image_type !== 'pdf' && !cropper && !pixelating" class="btn btn-sm btn-primary" @click="pixelateImage">Pixelate</button>
            </div>
            

            <div v-else class="alert alert-danger" role="alert">
                <div class="d-flex align-items-center justify-content-start">
                    <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
                    <span><strong>Problem!</strong> The system is unable to detect a valid event image attached to this claim. </span>
                </div><!-- d-flex -->
            </div><!-- alert -->

            <div v-show="cropper" class="">

                <button class="btn btn-sm btn-danger mg-b-sm" @click="cancelEdit">Cancel Edit</button>
                <button class="btn btn-sm btn-success mg-b-sm" @click="cropAndSave">Crop and Save</button>

                <br/>
                <br/>
                
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class="btn btn-sm btn-primary" @click="rotate(rotateSpeed * -1)">Rotate Anti-Clockwise</button>
                        </div>
                        <input class="form-control" type="number" step="1" min="1" max="360" v-model="rotateSpeed">
                        <div class="input-group-append">
                            <button class="btn btn-sm btn-primary" @click="rotate(rotateSpeed)">Rotate Clockwise</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="pixelating" class="">
                
                <button class="btn btn-sm btn-danger" style="margin-right: 10px;" @click="cancelPixelation">Cancel Edit</button>
                <button class="btn btn-sm btn-primary" :disabled="addingRectangle" style="margin-right: 10px;" @click="addRectangle">Add Rectangle</button>
                <button class="btn btn-sm btn-success" @click="pixelateAndSave">Pixelate and Save</button>

                <br/>
                <br/>

            </div>
        </div>

          <!-- STEP 1 -->
          <div class="col-6" v-show="step === 0">
              <h4 style="color: white;">Step 1. Vehicle Image</h4>
              
              <div class="form-group">

                  <label class="form-control-label">Can you see and read the vehicle registration in the vehicle image? <span class="text-danger">*</span></label>
                  <select @change="handleCanSeeRegChange" class="form-control form-control-dark" v-model="steps[0].can_read">
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                  </select>

              </div>

              <div class="form-group" v-show="steps[0].can_read">
  
                  <label class="form-control-label">Please enter the vehicle registration that you can see in the image <span class="text-danger">*</span></label>
                  <input type="text" @keydown.enter="verifyStepOne" :id="claim.id + '_reg_input'" class="form-control form-control-dark" v-model="steps[0].registration" placeholder="Enter the vehicle registration" />

              </div>

              <button v-show="steps[0].can_read === false || steps[0].registration !== null" class="btn btn-sm btn-success mg-t-20" @click="verifyStepOne">Next Step</button>

          </div>

          <!-- STEP 2 -->
          <div class="col-6" v-show="step === 1">
              <h4 style="color: white;">Step 2. Make, Model and Colour</h4>

              <p>Try to use your best judgement when deciding if the make, model and colour match. If you aren't sure what the vehicle should look like we have provided some reference images below.</p>

              <p><strong>Expected Make:</strong> {{ claim.make }}</p>
              <p><strong>Expected Model:</strong> {{ claim.model }}</p>
              <p><strong>Expected Colour:</strong> {{ claim.colour }}</p>

              <p>Adjust the year to update the example images.</p>

              <div class="form-group">

                  <label class="form-control-label">Year</label>
                  <select class="form-control form-control-dark" @change="updateSearchTerm" v-model="year">
                      <option value="2030">2030</option>
                      <option value="2029">2029</option>
                      <option value="2028">2028</option>
                      <option value="2027">2027</option>
                      <option value="2026">2026</option>
                      <option value="2025">2025</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1999">1999</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>
                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                      <option value="1970">1970</option>
                      <option value="1969">1969</option>
                      <option value="1968">1968</option>
                      <option value="1967">1967</option>
                      <option value="1966">1966</option>
                      <option value="1965">1965</option>
                      <option value="1964">1964</option>
                      <option value="1963">1963</option>
                      <option value="1962">1962</option>
                      <option value="1961">1961</option>
                      <option value="1960">1960</option>
                      <option value="1959">1959</option>
                      <option value="1958">1958</option>
                      <option value="1957">1957</option>
                      <option value="1956">1956</option>
                      <option value="1955">1955</option>
                      <option value="1954">1954</option>
                      <option value="1953">1953</option>
                      <option value="1952">1952</option>
                      <option value="1951">1951</option>
                      <option value="1950">1950</option>
                      <option value="1949">1949</option>
                      <option value="1948">1948</option>
                      <option value="1947">1947</option>
                      <option value="1946">1946</option>
                      <option value="1945">1945</option>
                      <option value="1944">1944</option>
                      <option value="1943">1943</option>
                      <option value="1942">1942</option>
                      <option value="1941">1941</option>
                      <option value="1940">1940</option>
                      <option value="1939">1939</option>
                      <option value="1938">1938</option>
                      <option value="1937">1937</option>
                      <option value="1936">1936</option>
                      <option value="1935">1935</option>
                      <option value="1934">1934</option>
                      <option value="1933">1933</option>
                      <option value="1932">1932</option>
                      <option value="1931">1931</option>
                      <option value="1930">1930</option>
                      <option value="1929">1929</option>
                      <option value="1928">1928</option>
                      <option value="1927">1927</option>
                      <option value="1926">1926</option>
                      <option value="1925">1925</option>
                      <option value="1924">1924</option>
                      <option value="1923">1923</option>
                      <option value="1922">1922</option>
                      <option value="1921">1921</option>
                      <option value="1920">1920</option>
                      <option value="1919">1919</option>
                      <option value="1918">1918</option>
                      <option value="1917">1917</option>
                      <option value="1916">1916</option>
                      <option value="1915">1915</option>
                      <option value="1914">1914</option>
                      <option value="1913">1913</option>
                      <option value="1912">1912</option>
                      <option value="1911">1911</option>
                      <option value="1910">1910</option>
                      <option value="1909">1909</option>
                      <option value="1908">1908</option>
                      <option value="1907">1907</option>
                      <option value="1906">1906</option>
                      <option value="1905">1905</option>
                      <option value="1904">1904</option>
                      <option value="1903">1903</option>
                      <option value="1902">1902</option>
                      <option value="1901">1901</option>
                      <option value="1900">1900</option>
                  </select>

              </div>

              <div id="carousel1" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner" role="listbox">
                  <div v-for="(example, index) in exampleImages" :key="'img-' + example" class="carousel-item" :class="{'active': index == currentCarouselIndex}">
                    <img style="width: 100%; height: auto;" :src="example">
                  </div>
                </div><!-- carousel-inner -->
                <a class="carousel-control-prev" href="javscript:void(0);" @click="prevCarousel" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="javscript:void(0);" @click="nextCarousel" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
            </div><!-- carousel -->

              <div class="form-group">

                  <label class="form-control-label">Are you satisfied the make, model and colour match? <span class="text-danger">*</span></label>
                  <select class="form-control form-control-dark" v-model="steps[1].mmc_match">
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                      <option :value="true">No Vehicle Image</option>
                  </select>

              </div>
              
              <div v-if="steps[1].mmc_match === false">

                  <p>If the make, model and colour do not match the expected vehicle this claim needs to be reset and reprocessed.</p>

                  <button :disabled="steps[1].submitting" class="btn btn-sm btn-danger" @click="clearPersonalData">Reset Claim</button>

              </div>

              <button v-show="steps[1].mmc_match === true" class="btn btn-sm btn-success mg-t-20" @click="verifyStepTwo">Next Step</button>

          </div>

      </div>

      <!-- STEP 3 - Receipt Checks -->
       <div v-show="step === 2" class="row">
          <div class="col-6">
              <SecureImage v-viewer v-if="till_receipt !== null && till_file_type !== 'pdf'" :filename="till_receipt.filename" :client_id="client_id" :claim_id="claim.id" :type="'till_receipt'" :site_id="claim.site_id" :storage_account="till_receipt.storage_account" style="max-height: 75vh; width: auto; max-width: 100%;" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :class="{'rotate-90': currentRotation == 90, 'rotate-180': currentRotation == 180, 'rotate-270': currentRotation == 270}" />

              <div v-if="till_receipt !== null && till_file_type !== 'pdf'" style="margin-top: 20px;">
                  <button class="btn btn-primary btn-sm" @click="rotateImage">Rotate</button>
              </div>

              <div v-if="till_receipt !== null && till_file_type == 'pdf'" style="max-height: 75vh; width: auto; max-width: 100%;">
                  <iframe :src="pdf_url" width="100%" style="height: 75vh;"></iframe>
              </div>

              <div v-if="till_receipt == null" class="alert alert-danger" role="alert">
                  <div class="d-flex align-items-center justify-content-start">
                      <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
                      <span><strong>Problem!</strong> The system is unable to detect a valid till receipt attached to this claim. </span>
                  </div><!-- d-flex -->
              </div><!-- alert -->

          </div>

          <div class="col-6">
              
              <p>Check the details below match the details on the receipt. If they do not, make sure to check notes, logs and site comments for a justification.</p>

              <div class="form-group">
                  <label class="form-control-label">Fuel Type</label>
                  <input type="text" disabled class="form-control form-control-dark" v-model="claim.fuel" placeholder="Fuel Type" />
              </div>

              <div class="form-group">
                <label class="form-control-label">Pump</label>
                <input type="number" disabled class="form-control form-control-dark" v-model="claim.pump" placeholder="Pump" />
                </div>

              <div class="form-group">
                  <label class="form-control-label">Litres</label>
                  <input type="number" disabled class="form-control form-control-dark" v-model="claim.litres" placeholder="Litres" />
              </div>

              <div class="form-group">
                  <label class="form-control-label">Value</label>
                  <input type="number" disabled class="form-control form-control-dark" v-model="claim.value" placeholder="Value" />
              </div>

              <div class="form-group" v-if="claim.fuel_second">
                  <label class="form-control-label">Fuel Type (Second)</label>
                  <input type="text" disabled class="form-control form-control-dark" v-model="claim.fuel_second" placeholder="Fuel Type" />
              </div>

              <div class="form-group" v-if="claim.litres_second">
                  <label class="form-control-label">Litres (Second)</label>
                  <input type="number" disabled class="form-control form-control-dark" v-model="claim.litres_second" placeholder="Litres" />
              </div>

              <div class="form-group" v-if="claim.value_second">
                  <label class="form-control-label">Value (Second)</label>
                  <input type="number" disabled class="form-control form-control-dark" v-model="claim.value_second" placeholder="Value" />
              </div>

              <div class="form-group" v-if="claim.pump_second">
                  <label class="form-control-label">Pump (Second)</label>
                  <input type="number" disabled class="form-control form-control-dark" v-model="claim.pump_second" placeholder="Pump" />
              </div>

              <button :disabled="steps[2].disabled" class="btn btn-sm btn-success mg-t-20" @click="verifyStepThree">Next Step</button>

          </div>

       </div>

       <!-- STEP 4 - Date/Time Checks -->
       <div class="row" v-show="step === 3">

          <div class="col-6">

              <!-- A gallery of evidence files for the user to review (images and/or pdfs) -->
              <div v-if="currentAssetType !== 'pdf'">
                  <SecureImage v-if="steps[3].currentAsset" v-viewer :filename="steps[3].currentAsset.filename" :client_id="client_id" :claim_id="claim.id" :type="'till_receipt'" :site_id="claim.site_id" :storage_account="steps[3].currentAsset.storage_account" style="max-height: 75vh; width: auto; max-width: 100%;" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :class="{'rotate-90': currentRotation == 90, 'rotate-180': currentRotation == 180, 'rotate-270': currentRotation == 270}" />
                  <button class="btn btn-primary btn-sm" @click="rotateImage">Rotate</button>
              </div>

              <div v-else>
                  <iframe :src="pdf_url" width="100%" style="height: 75vh;"></iframe>
              </div>

              <div v-if="steps[3].currentAsset !== null">
                  <button class="btn btn-sm btn-success mg-t-20" @click="confirmAsset">Confirm Asset Review</button>
                  <p v-show="steps[3].currentAsset.checked" class="text-success">Asset Review Confirmed</p>
              </div>

          </div>

          <div class="col-6">

              <h4 style="color: white;">Step 4. Date and Time</h4>

              <p>Check the date and time on the receipt and ensure it matches the date and time of the Customer Not at Fault.</p>

              <div class="form-group">
                  <label class="form-control-label">Date</label>
                  <input type="date" disabled class="form-control form-control-dark" :value="incident_date" placeholder="Date" />
              </div>

              <div class="form-group">
                  <label class="form-control-label">Time</label>
                  <input type="time" disabled class="form-control form-control-dark" :value="incident_time" placeholder="Time" />
              </div>

              <p>If you need to edit the date and time, please click the incident timestamp towards the top of the file above.</p>

              <p class="tx-warning">In order to continue, you must cycle through each of the evidence files to the left.</p>

              <button :disabled="!allEvidenceChecked" class="btn btn-sm btn-success mg-t-20" @click="verifyStepFour">Next Step</button>

          </div>
       </div>

      <!-- STEP 5 - DVLA Response -->
      <div v-show="step === 4" class="row" style="min-width: 80vw;">
          <div class="col-6">

              <iframe v-if="selectedAsset !== null" :src="pdf_url" width="100%" height="1000px" min-height="500px;"></iframe>

              <iframe v-if="preview_url !== null" :src="preview_url" width="100%" height="1000px" min-height="500px;"></iframe>

          </div>
          <div class="col-6">

              <p>Please review the response received on the left and ensure that the name and address are properly formatted!</p>

              <p class="tx-warning">Address Requirements</p>
              <table class="table">
                  <thead>
                      <tr>
                          <th>Requirement</th>
                          <th>Status</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <th>Maximum length of 35 characters per line</th>
                          <td>
                              <span v-show="!addressLengthRequirementsMet" class="tx-danger">Failed</span>
                              <span v-show="addressLengthRequirementsMet" class="tx-success">Passed</span>
                          </td>
                      </tr>
                      <tr>
                          <th>Valid Postcode</th>
                          <td>
                              <span v-show="!steps[3].postcodeValid" class="tx-danger">Failed</span>
                              <span v-show="steps[3].postcodeValid" class="tx-success">Passed</span>
                          </td>
                      </tr>
                  </tbody>
              </table>

              <div class="form-group">
                  <label class="form-control-label" style="width: 100%;">Name</label>
                  <input :class="{'error': response.name?.length > 35}" type="text" class="form-control form-control-dark" v-model="response.name" placeholder="Name" />
                  <span v-show="response.name?.length > 35" class="tx-danger" style="font-size: 12px">Too many characters ({{ response.name?.length }}/35)</span>
              </div>

              <div class="form-group">
                  <label class="form-control-label">Address Line 1</label>
                  <input type="text" class="form-control form-control-dark" v-model="response.address_one" placeholder="Address Line 1" />
                  <span v-show="response.address_one?.length > 35" class="tx-danger" style="font-size: 12px">Too many characters ({{ response.address_one?.length }}/35)</span>
              </div>

              <div class="form-group">
                  <label class="form-control-label">Address Line 2</label>
                  <input type="text" class="form-control form-control-dark" v-model="response.address_two" placeholder="Address Line 2" />
                  <span v-show="response.address_two?.length > 35" class="tx-danger" style="font-size: 12px">Too many characters ({{ response.address_two?.length }}/35)</span>
              </div>

              <div class="form-group">
                  <label class="form-control-label">Address Line 3</label>
                  <input type="text" class="form-control form-control-dark" v-model="response.address_three" placeholder="Address Line 3" />
                  <span v-show="response.address_three?.length > 35" class="tx-danger" style="font-size: 12px">Too many characters ({{ response.address_three?.length }}/35)</span>
              </div>

              <div class="form-group">
                  <label class="form-control-label">Address Line 4 (Postcode)</label>
                  <input :disabled="steps[3].postcodeValid" :class="{'validated': steps[3].postcodeValid, 'error': !steps[3].postcodeValid || response.address_four?.length > 35}" type="text" class="form-control form-control-dark" v-model="response.address_four" placeholder="Address Line 4" />
                  <span v-show="response.address_four?.length > 35" class="tx-danger" style="font-size: 12px">Too many characters ({{ response.address_four?.length }}/35)</span>
                  <span v-show="!steps[3].postcodeValid" class="tx-danger" style="font-size: 12px">Invalid Postcode</span>
                  <span v-show="steps[3].postcodeValid" class="tx-success" style="font-size: 12px">Valid Postcode</span>
              </div>

              <div class="form-group">
                  <label class="form-control-label">Special Notes</label>
                  <textarea class="form-control form-control-dark" disabled v-model="response.special_notes" placeholder="Special Notes"></textarea>
              </div>

              <div class="form-group">
                  <label class="form-control-label">Reason for changes</label>
                  <textarea class="form-control form-control-dark" v-model="response.reason" placeholder="Reason for changes..."></textarea>
              </div>

              <p>
                  <p>You must check the postcode before the notice preview will show.</p>
                  <button :disabled="steps[3].postcodeValid" class="btn btn-sm btn-primary mg-t-20" @click="validatePostcode">Check Postcode</button>
              </p>

              <p>
                  <button :disabled="!steps[3].postcodeValid" class="btn btn-sm btn-primary mg-t-20" @click="downloadPreview">Reload Notice Preview</button>
              </p>

              <p>
                  <span class="tx-warning">This will generate and send a notice.</span><br/>
                  <button :disabled="!steps[3].postcodeValid" class="btn btn-sm btn-primary mg-t-20" @click="saveChangesToResponse">Save Keeper Details</button>
              </p>

              <p>
                  <span class="tx-warning">This will generate and send a notice but will not update the keeper details if you have made changes.</span>
                  <button :disabled="!steps[3].postcodeValid" class="btn btn-sm btn-success mg-t-20" @click="acceptResponse">Accept Response</button>
              </p>

          </div>
      </div>
  </div>
</template>

<script>

import axios from 'axios';
import authHeader from '@/services/auth-header';
import SecureImage from '@/components/SecureImage.vue';
import { DateTime } from 'luxon';
import "jimp";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: "CnafDvlaReview",
  components: {
      SecureImage
  },
  props: {
      claim: {
          type: Object,
          required: true
      },
      client_id: {
          type: [Number, String],
          required: false
      }
  },
  data(){
      return {
          response: {
              name: "",
              address_one: "",
              address_two: "",
              address_three: "",
              address_four: "",
              special_notes: "",
              reason: ""
          },
          pdf_url: null,
          currentPage: 1,
          pageCount: 0,
          currentRotation: 0,
          selectedAsset: null,
          preview_url: null,
          searchTerm: '',
          year: null,
          exampleImages: [],
          currentRotation: 0,
          step: 0,
          steps: [
              {
                  can_read: null,
                  registration: null
              },
              {
                  mmc_match: null,
                  submitting: false
              },
              {
                  disabled: true
              },
              {
                  requiredEvidence: [],
                  currentAsset: null,
                  postcodeValid: false,
                  submitting: false,
                  errorMsg: null,
                  error: false
              }
          ],
          cropper: null,
            rotateSpeed: 1,
            pixelating: false,
            rectangles: [],
            canvas: null,
            addingRectangle: false,
            isDown: false,
            origX: null,
            origY: null,
            drawRect: null,
            widthAspectRatio: 1,
            heightAspectRatio: 1,
            finalHeight: 0,
            finalWidth: 0,
            currentCarouselIndex: 0
      }
  },
  mounted(){
      this.searchTerm = `${this.claim.make || ''} ${this.claim.model || ''} ${this.claim.colour || ''}`;
      if(this.claim.override_mmc == 1){
          this.searchTerm = `${this.claim.vehicle_make || ''} ${this.claim.vehicle_model || ''} ${this.claim.vehicle_colour || ''}`;
      }
      this.loadResponse();
      setTimeout(() => {
          if(this.till_file_type == 'pdf'){
              if(this.pdf_url){
                  URL.revokeObjectURL(this.pdf_url);
              }
              axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.till_receipt.filename}?site_id=${this.claim.site_id}&storage_account=${this.till_receipt.storage_account || 1}&client_id=${this.client_id}`, {
                  responseType: 'arraybuffer',
                  headers: authHeader()
              })
              .then(response => {
                  let blob = new Blob([response.data], {type: 'application/pdf'});
                  this.pdf_url = URL.createObjectURL(blob);
              })
              .catch(error => {
                  console.log(error);
                  this.pdf_url = "";
              });
          }
      }, 1500);

      // Required evidence for the Date/Time Checks
      this.claim.assets.forEach((asset) => {
          if(['vehicle_image', 'till_receipt', 'other', 'additional_information', 'customer_image', 'transfer_of_liability'].includes(asset.type)){
              this.steps[3].requiredEvidence.push(asset);
          }
      });

      this.steps[3].currentAsset = this.steps[3].requiredEvidence[0];
  },
  beforeUnmount(){
      if(this.pdf_url){
          window.URL.revokeObjectURL(this.pdf_url);
      }
      if(this.preview_url){
          window.URL.revokeObjectURL(this.preview_url);
      }
  },
  methods: {
    nextCarousel(){
            if(this.currentCarouselIndex == this.exampleImages.length - 1){
                this.currentCarouselIndex = 0;
            }
            else{
                this.currentCarouselIndex++;
            }
        },
        prevCarousel(){
            if(this.currentCarouselIndex == 0){
                this.currentCarouselIndex = this.exampleImages.length - 1;
            }
            else{
                this.currentCarouselIndex--;
            }
        },  
    handleCanSeeRegChange(){
            this.$emit('pendingLock', this.steps[0].can_read);
            document.getElementById(this.claim.id + '_reg_input').focus();
        },
      downloadPreview(){
          if(this.preview_url){
              window.URL.revokeObjectURL(this.preview_url);
          }
          axios.post(`https://api.varsanpr.com/api/claims/${this.claim.id}/preview?site_id=${this.claim.site.id}&client_id=${this.client_id}&notice_type=cnaf`, {
              name: this.response.name,
              address_one: this.response.address_one,
              address_two: this.response.address_two,
              address_three: this.response.address_three,
              address_four: this.response.address_four,
          }, {
              responseType: 'arraybuffer',
              headers: authHeader()
          })
          .then(response => {
              let blob = new Blob([response.data], {type: 'application/pdf'});
              this.preview_url = window.URL.createObjectURL(blob);
          })
          .catch(error => {
              console.log(error);
              this.preview_url = "";
          })
      },
    loadResponse(){
      axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper?client_id=${this.client_id}`, {
          headers: authHeader()
      })
      .then(response => {
          this.response = response.data;
          this.response.reason = "";
      })
      .catch(error => {
          this.$error("Failed to locate keeper details, please try again.", error);
      })
    },
    acceptResponse(){
      axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/status`, {
          client_id: this.client_id,
          job: 'cnaf',
          send: true
      }, {
          headers: authHeader()
      })
      .then(response => {
          this.$emit('changeMade');
          this.$success("Sent CNAF Notice!");
          this.$emit('reload');
          this.emitter.emit(`claim_${this.claim.id}_trigger_next`);
      })
      .catch(error => {
          this.$error("Failed to process notice!", error);
      })
    },
      downloadPdf(){
          let files = this.claim.assets.filter((a) => {
              return a.type === 'dvla_response';
          });

          console.log(`Found ${files.length} files for dvla response`);

          if(files.length < 1) return;

          let file = files[files.length - 1];

          console.log(`Got file: ${file.filename}`);

          if(this.pdf_url){
              window.URL.revokeObjectURL(this.pdf_url);
          }

          axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${file.filename}?site_id=${this.claim.site.id}&storage_account=${file.storage_account || 1}&client_id=${this.client_id}`, {
              responseType: 'arraybuffer',
              headers: authHeader()
          })
          .then(response => {
              let blob = new Blob([response.data], {type: 'application/pdf'});
              this.pdf_url = window.URL.createObjectURL(blob);
              this.selectedAsset = file;
          })
          .catch(error => {
              console.log(error);
              this.pdf_url = "";
          })
      },

      rotateImage(){
          if(this.currentRotation == 0){
              this.currentRotation = 90;
          }
          else if(this.currentRotation == 90){
              this.currentRotation = 180;
          }
          else if(this.currentRotation == 180){
              this.currentRotation = 270;
          }
          else if(this.currentRotation == 270){
              this.currentRotation = 0;
          }
      },

      updateExamples(){
          this.searchTerm = `${this.steps[1].make || ''} ${this.steps[1].model || ''} ${this.steps[1].colour || ''} ${this.year || ''}`;
          this.loadExampleImages();
      },
      updateSearchTerm(){
          this.searchTerm = `${this.claim.make || ''} ${this.claim.model || ''} ${this.claim.colour || ''} ${this.year || ''}`;
          this.loadExampleImages();
      },
      loadExampleImages(){
          console.log(`Searching for: ${this.searchTerm}`);
          axios.get(`https://api.varsanpr.com/api/misc/imagesearch?search=${this.searchTerm}`, {
              headers: authHeader()
          })
          .then((response) => {
              this.exampleImages = response.data.images;
          })
          .catch((error) => {
              console.log(error);
          });
      },

      verifyStepOne(){
          if(this.steps[0].can_read === false){
              console.log(`Triggering load example images`);
              this.loadExampleImages();
              this.step = 1;
              return;
          }

          if(this.steps[0].registration === null){
              this.$error("Please enter the vehicle registration before continuing.");
              return;
          }

          if(this.steps[0].registration !== this.claim.registration){
              this.$error("The registration you have entered does not match the registration on the claim.");
              return;
          }

          this.loadExampleImages();
          this.step = 1;
      },
  
      verifyStepTwo(){

          if(this.steps[1].mmc_match === false){
              if(this.steps[1].make === null || this.steps[1].model === null || this.steps[1].colour === null){
                  this.$error("Please enter the make, model and colour before continuing.");
                  return;
              }
          }

          this.step = 2;

          setTimeout(() => {
              this.steps[2].disabled = false;
          }, 5000);

      },

      verifyStepThree(){

          this.step = 3;

      },

      verifyStepFour(){
              
          this.step = 4;
          this.downloadPdf();

      },

      getNextAsset(){

          let currentIndex = this.steps[3].requiredEvidence.indexOf(this.steps[3].currentAsset);

          if(currentIndex === this.steps[3].requiredEvidence.length - 1){
              this.steps[3].currentAsset = this.steps[3].requiredEvidence[0];
          }
          else {
              this.steps[3].currentAsset = this.steps[3].requiredEvidence[currentIndex + 1];
          }

          if(this.steps[3].currentAsset.filename.slice((this.steps[3].currentAsset.filename.lastIndexOf(".") - 1 >>> 0) + 2) == 'pdf'){
              if(this.pdf_url){
                  window.URL.revokeObjectURL(this.pdf_url);
              }
              axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.steps[3].currentAsset.filename}?site_id=${this.claim.site_id}&storage_account=${this.steps[3].currentAsset.storage_account || 1}&client_id=${this.client_id}`, {
                  responseType: 'arraybuffer',
                  headers: authHeader()
              })
              .then(response => {
                  let blob = new Blob([response.data], {type: 'application/pdf'});
                  this.pdf_url = URL.createObjectURL(blob);
              })
              .catch(error => {
                  console.log(error);
                  this.pdf_url = "";
              });
          }

      },

      confirmAsset(){

          this.steps[3].currentAsset.checked = true;

          this.getNextAsset();

      },

      validatePostcode(){
          axios.get(`https://api.varsanpr.com/api/misc/address/${this.response.address_four}`, {
              headers: authHeader()
          })
          .then(response => {
              if(response.data.addresses.length > 0){
                  this.steps[3].postcodeValid = true;
                  this.downloadPreview();
              }
              else {
                  this.steps[3].postcodeValid = false;
                  this.$error("The postcode you have entered is not valid.");
              }
          })
          .catch(error => {
              console.log(error);
              this.steps[3].postcodeValid = false;
              this.$error("The postcode you have entered is not valid.");
          })
      },

      saveChangesToResponse(){
          if(this.steps[3].submitting) return;
          this.steps[3].submitting = true;

          if(!this.response.reason){
              this.$error("Please provide a reason for the changes.");
              this.steps[3].submitting = false;
              return;
          }

          if(this.response.reason.length < 10){
              this.$error("Please provide a reason for the changes that is at least 10 characters long.");
              this.steps[3].submitting = false;
              return;
          }

          axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper`, {...this.response, hirer: false, client_id: this.client_id}, {
              headers: authHeader()
          })
              .then(response => {
                  if(response.data.errors){
                      console.log("Has errors");
                      this.steps[3].errorMsg = "";
                      for(let key in Object.keys(response.data.errors)){
                          let rules = response.data.errors[key];
                          for(let rule in rules){
                              this.steps[3].errorMsg += `<br/>${rule.rule} for ${key}: ${rule.message}`;
                          }
                          this.steps[3].errorMsg += `${response.data.errors}`;
                      }
                      this.steps[3].error = true;
                      this.steps[3].submitting = false;
                  }else{
                      this.$success("Saved changes to DVLA response.");
                      this.$emit('changeMade');
                      this.$emit('reload');
                      this.emitter.emit(`claim_${this.claim.id}_trigger_next`);
                  }
              })
              .catch(error => {
                  console.log(error);
                  this.steps[3].submitting = false;
                  this.$error("Failed to save changes to DVLA response.", error)
                  if(error.response){
                      if(error.response.status == 422){
                          this.steps[3].errorMsg = "";
                          for(let key in Object.keys(response.data.errors)){
                              let rules = response.data.errors[key];
                              for(let rule in rules){
                                  this.steps[3].errorMsg += `<br/>${rule.rule} for ${key}: ${rule.message}`;
                              }
                              this.steps[3].errorMsg += `${response.data.errors}`;
                          }
                          this.steps[3].error = true;
                      }
                      if(error.response.status == 401){
                          this.steps[3].errorMsg = error.response.data.message;
                          this.steps[3].error = true;
                      }
                  }
              });
      },

      async clearPersonalData(){
          if(this.steps[1].updating) return;
          this.steps[1].updating = true;
          const {value: result} = await Swal.fire({
              title: `Are you sure you wish to reset this claim? It will remove all personal data and cannot be undone!`,
              showCancelButton: true,
              confirmButtonText: 'Reset Claim',
              cancelButtonText: 'Cancel',
          });
          if(result){
            console.log("Clearing data!");

            axios.delete(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper`, {
                  data: {
                    client_id: this.client_id
                  },
                  headers: authHeader()
              })
              .then(response => {
                  this.$success("Reset claim!");
                  this.$emit('update');
                  this.$emit('changeMade');
                  this.$emit('reload');
                  this.emitter.emit(`claim_${this.claim.id}_trigger_next`);
              })
              .catch(error => {
                  this.$error("Failed to reset claim!", error);
              })
              .finally(() => {
                  this.steps[1].submitting = false;
              })

          }else{
            console.log("Not clearing data", result);
            this.steps[1].submitting = false;
          }
      },

      // CROP / PIXELATE FUNCTIONS

      editImage(){
            let image;
            if(this.vehicle_image !== null && this.vehicle_image_type !== 'pdf'){
                image = document.getElementById('dvlareview_' + this.claim.id + 'vi');
            }else{
                return;
            }
            console.log(image);
            this.cropper = new Cropper(image, {
                viewMode: 2,
                rotatable: true,
                scalable: true,
                ready: function () {
                    var cropper = this.cropper;
                    var containerData = cropper.getContainerData();
                    var cropBoxData = cropper.getCropBoxData();
                    var aspectRatio = cropBoxData.width / cropBoxData.height;
                    var newCropBoxWidth;

                    if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
                        newCropBoxWidth = cropBoxData.height * ((minAspectRatio + maxAspectRatio) / 2);

                        cropper.setCropBoxData({
                        left: (containerData.width - newCropBoxWidth) / 2,
                        width: newCropBoxWidth
                        });
                    }
                },
                cropmove: function () {
                    var cropper = this.cropper;
                    var cropBoxData = cropper.getCropBoxData();
                    var aspectRatio = cropBoxData.width / cropBoxData.height;

                    if (aspectRatio < minAspectRatio) {
                        cropper.setCropBoxData({
                        width: cropBoxData.height * minAspectRatio
                        });
                    } else if (aspectRatio > maxAspectRatio) {
                        cropper.setCropBoxData({
                        width: cropBoxData.height * maxAspectRatio
                        });
                    }
                },
            });
        },
        cropAndSave(){
            this.cropper.getCroppedCanvas({
                fillColor: '#fff'
            }).toBlob((blob) => {
                // this.uploadImage(blob);

                let formData = new FormData();
                formData.append("file", blob, "cropped_" + this.vehicle_image.filename);
                formData.append("client_id", this.client_id);
                formData.append("type", this.vehicle_image.type);
                axios.put(`https://api.varsanpr.com/api/claims/evidence/${this.claim.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        ...authHeader()
                    }
                })
                .then(response => {
                    this.$success("Uploaded evidence file!");
                    axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.vehicle_image.filename}`, {
                        type: 'other',
                        client_id: this.client_id
                    }, {
                        headers: authHeader()
                    })
                    .then(response => {
                        if(response.data.success){
                            this.$success("Successfully updated evidence type!");
                            // this.$emit('refresh');
                        }
                        this.$emit('changeMade');
                        this.$emit("reload");
                        this.cropper.destroy();
                        this.cropper = null;
                    })
                    .catch(error => {
                        this.$error("Failed to update evidence type.", error);
                    });
                })
                .catch(error => {
                    this.$error("Failed to upload evidence file.", error);
                })
                .finally(() => {
                    // this.addEvidence.uploading = false;
                })

            }, "image/jpeg");
        },
        rotate(val){
            this.cropper.rotate(val);
        },
        cancelEdit(){
            this.cropper?.destroy();
            this.cropper = null;
        },

        pixelateImage(){
            this.pixelating = true;
            this.rectangles = [];
            if(!this.canvas){
                this.canvas = new fabric.Canvas('c');
            }

            let selectedImage;
            if(this.vehicle_image !== null && this.vehicle_image_type !== 'pdf'){
                selectedImage = document.getElementById('dvlareview_' + this.claim.id + 'vi');
            }else{
                return;
            }

            let img = new Image();
            img.onload = () => {
                let maxWidth = 750;
                let maxHeight = 500;

                let imgWidth = img.width;
                let imgHeight = img.height;

                console.log(`Image Dimensions: `, imgWidth, imgHeight);

                let widthAspectRatio = maxWidth / imgWidth;
                let heightAspectRatio = maxHeight / imgHeight;

                console.log(`Aspect Ratios: `, widthAspectRatio, heightAspectRatio);

                this.widthAspectRatio = widthAspectRatio;
                this.heightAspectRatio = heightAspectRatio;

                let finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

                let finalHeight = imgHeight * finalAspectRatio;
                let finalWidth = imgWidth * finalAspectRatio;

                this.finalHeight = finalHeight;
                this.finalWidth = finalWidth;

                let imgTop = 0;

                let imgLeft = 0;

                let nsgImage = new fabric.Image(img).scale(finalAspectRatio);
                nsgImage.set({left: imgLeft, top: imgTop});

                this.canvas.setBackgroundImage(nsgImage, () => this.canvas.renderAll());

                this.canvas.setWidth(finalWidth);
                this.canvas.setHeight(finalHeight);
            }
            img.src = selectedImage.src;
        },
        addRectangle(){
            if(this.addingRectangle){
                this.addingRectangle = false;
                return;
            }
            this.addingRectangle = true;
            this.canvas.on('mouse:down', (o) => {
                this.isDown = true;
                var pointer = this.canvas.getPointer(o.e);
                this.origX = pointer.x;
                this.origY = pointer.y;
                var pointer = this.canvas.getPointer(o.e);
                this.drawRect = new fabric.Rect({
                    left: this.origX,
                    top: this.origY,
                    originX: 'left',
                    originY: 'top',
                    width: pointer.x-this.origX,
                    height: pointer.y-this.origY,
                    angle: 0,
                    fill: 'rgba(0,0,0,0.2)',
                    transparentCorners: false
                });
                this.rectangles.push(this.drawRect);
                this.canvas.add(this.drawRect);
            });

            this.canvas.on('mouse:move', (o) => {
                if(!this.isDown) return;
                var pointer = this.canvas.getPointer(o.e);

                if(this.origX>pointer.x){
                    this.drawRect.set({ left: Math.abs(pointer.x) });
                }
                if(this.origY>pointer.y){
                    this.drawRect.set({ top: Math.abs(pointer.y) });
                }

                this.drawRect.set({ width: Math.abs(this.origX - pointer.x) });
                this.drawRect.set({ height: Math.abs(this.origY - pointer.y) });

                this.canvas.renderAll();
            });

            this.canvas.on('mouse:up', (o) => {
                this.isDown = false;
                this.canvas.off('mouse:down');
                this.canvas.off('mouse:move');
                this.canvas.off('mouse:up');
                this.addingRectangle = false;

                console.log(this.rectangles);
            })
        },
        async pixelateAndSave(){
            // Using the rectangles stored in this.rectangles, use jimp to apply a pixelation to those sections of the image
            // Then save the image

            let selectedImage;
            if(this.vehicle_image !== null && this.vehicle_image_type !== 'pdf'){
                selectedImage = document.getElementById('dvlareview_' + this.claim.id + 'vi');
            }else{
                return;
            }

            await Jimp.read(selectedImage.src)
                .then(async (jimpImage) => {

                    // Check aspect ratio
                    console.log(jimpImage.bitmap.width, jimpImage.bitmap.height);
                    let widthAspectRatio = jimpImage.bitmap.width / this.finalWidth;
                    let heightAspectRatio = jimpImage.bitmap.height / this.finalHeight;
                    console.log(widthAspectRatio, heightAspectRatio);

                    for await (let rect of this.rectangles){

                        let x = rect.left * widthAspectRatio;
                        let y = rect.top * heightAspectRatio;
                        let width = rect.width * widthAspectRatio;
                        let height = rect.height * heightAspectRatio;
                        // width = width * 2
                        console.log(`Aspect Ratio:`, widthAspectRatio, heightAspectRatio);

                        console.log(`Rect: `, rect.left, rect.top, rect.width, rect.height);
                        console.log(`Final: `, x, y, width, height);

                        await jimpImage.pixelate(
                            50,
                            x,
                            y,
                            width,
                            height
                        );
                    }

                    jimpImage.getBuffer(Jimp.MIME_JPEG, (err, buffer) => {
                        if(err){
                            console.error(err);
                            return;
                        }

                        let blob = new Blob([buffer], {type: 'image/jpeg'});

                        let formData = new FormData();
                        formData.append("file", blob, "pixelated_" + this.vehicle_image.filename);
                        formData.append("client_id", this.client_id);
                        formData.append("type", this.vehicle_image.type);
                        axios.put(`https://api.varsanpr.com/api/claims/evidence/${this.claim.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                ...authHeader()
                            }
                        })
                        .then(response => {
                            this.$success("Uploaded evidence file!");
                            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.vehicle_image.filename}`, {
                                type: 'other',
                                client_id: this.client_id
                            }, {
                                headers: authHeader()
                            })
                            .then(response => {
                                if(response.data.success){
                                    this.$success("Successfully updated evidence type!");
                                    // this.$emit('refresh');
                                }
                                this.$emit('changeMade');
                                this.$emit("reload");
                            })
                            .catch(error => {
                                this.$error("Failed to update evidence type.", error);
                            });
                        })
                        .catch(error => {
                            this.$error("Failed to upload evidence file.", error);
                        })
                        .finally(() => {
                            // this.addEvidence.uploading = false;
                            this.canvas.clear();
                            this.rectangles = [];
                            this.addingRectangle = false;
                            this.origX = null;
                            this.origY = null;
                            this.drawRect = null;
                            this.isDown = false;
                            this.widthAspectRatio = 1;
                            this.heightAspectRatio = 1;
                            this.finalHeight = 0;
                            this.finalWidth = 0;
                            this.pixelating = false;
                        })

                    });

                })
                .catch(err => {
                    console.error(err);
                })
        },
        cancelPixelation(){
            this.pixelating = false;
        }

  },
  computed: {
      vehicle_image: function(){
          return this.claim.assets.filter((asset) => {
              return asset.type == 'vehicle_image'
          })[0] || null;
      },
      vehicle_image_type: function(){
            if(this.vehicle_image){
                return this.vehicle_image.filename.slice((this.vehicle_image.filename.lastIndexOf(".") - 1 >>> 0) + 2);
            }
        },
      till_receipt: function(){
          return this.claim.assets.filter((asset) => {
              return asset.type == 'till_receipt'
          })[0] || null;
      },
      till_file_type: function(){
          if(this.till_receipt){
              return this.till_receipt.filename.slice((this.till_receipt.filename.lastIndexOf(".") - 1 >>> 0) + 2);
          }
      },
      incident_date: function(){
          return DateTime.fromSeconds(this.claim.incident_timestamp).toISODate();
      },
      incident_time: function(){
          return DateTime.fromSeconds(this.claim.incident_timestamp).toISOTime({includeOffset: false, suppressMilliseconds: true});
      },
      allEvidenceChecked: function(){
          return this.steps[3].requiredEvidence.filter((asset) => {
              return asset.checked === true;
          }).length === this.steps[3].requiredEvidence.length;
      },
      currentAssetType: function(){
          if(this.steps[3].currentAsset){
              return this.steps[3].currentAsset.filename.slice((this.steps[3].currentAsset.filename.lastIndexOf(".") - 1 >>> 0) + 2);
          }
      },
      addressLengthRequirementsMet: function(){
          let result = true;
          if(this.response.name.length > 35) result = false;
          if(this.response.address_one.length > 35) result = false;
          if(this.response.address_two.length > 35) result = false;
          if(this.response.address_three.length > 35) result = false;
          if(this.response.address_four.length > 35) result = false;
          return result;
      }
  },
  watch: {
      'steps[0].registration': function(newVal, oldVal){
          if(newVal === null || newVal === undefined) return;
          if(newVal.length > 11){
              this.steps[0].registration = oldVal;
          }
          this.steps[0].registration = newVal.toUpperCase();
      }
  }
}
</script>

<style scoped>
  .info-alert {
      border-left: 5px solid #17a2b8;
  }

  .carousel-control-prev-icon::before, .carousel-control-next-icon::before {
      background-color: #000000;
      border-radius: 7px;
      padding: 10px;
  }
  
  .rotate-90 {
      transform: rotate(90deg);
  }
  
  .rotate-180 {
      transform: rotate(180deg);
  }
  
  .rotate-270 {
      transform: rotate(270deg);
  }

  .error {
      border-color: #DC3545; 
  }

  .validated {
      border-color: #28A745;
  }
</style>