<template>
    <div style="width: 100%;">
        
        <!-- STEP 1: Detail check -->
        <div class="row" v-if="currentStep === 0">
            <div class="col-12">

                <div class="form-group">
                    <label class="form-label">Looking at the information above, did the vehicle overstay?</label>
                    <select class="form-control" style="width: 15rem;" v-model="steps[0].overstay">
                        <option :value="null">Please select</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>
                </div>

                <p v-if="steps[0].overstay === false" class="tx-warning">If the vehicle did not overstay, then you should cancel this parking charge by heading to the management tab.</p>

                <div v-else-if="steps[0].overstay === true">
                    <button class="btn btn-sm btn-primary" @click="nextStep()">Next Step</button>
                </div>

            </div>
        </div>

        <!-- STEP 2: Entry image -->
        <div class="row" v-if="currentStep === 1">
            <div :class="{'col-6': !pixelating, 'col-12': pixelating}">

                <div v-show="!pixelating">
                    <secure-image v-viewer :id="`event_` + entryEvent.id + '_vi'" v-if="entryEvent !== null && !entryEvent.grayscaled" :filename="entryEvent.image" :site_id="claim.site.id"  :endpoint="'/api/event/image/'" :storage_account="entryEvent.storage_account" style="width: 100%; display: block;"></secure-image>
                    <img v-else-if="entryEvent !== null && entryEvent.grayscaled" :id="`event_` + entryEvent.id + '_vi'" :src="entryEvent.grayscaled" style="width: 100%; display: block;" v-viewer />

                    <submit-button  :clicked="grayscaling" @click="grayscale(entryEvent)" text="Grayscale" background="btn btn-sm btn-warning" v-if="$can('claims-process')"></submit-button>
                    <button v-if="entryEvent != null && !pixelating" class="btn btn-sm btn-primary" @click="pixelateImage(entryEvent)">Pixelate</button>
                </div>
                
                <div v-show="pixelating">

                    <canvas :id="`event_` + entryEvent.id + '_canvas'" width="750" height="500"></canvas>

                    <div class="row">
                        <div class="col-12">
                            <button class="btn btn-sm btn-danger" style="margin-right: 10px;" @click="cancelPixelation">Cancel Edit</button>
                            <button class="btn btn-sm btn-primary" :disabled="addingRectangle" style="margin-right: 10px;" @click="addRectangle">Add Rectangle</button>
                            <button class="btn btn-sm btn-success" @click="pixelateAndSave(entryEvent)">Pixelate and Save</button>
                        </div>
                    </div>

                </div>

            </div>
            <div class="col-6">

                <p>Using the entry image to the left, complete the following checks.</p>

                <div class="form-group">
                    <label class="form-label">Vehicle Registration</label>
                    <input type="text" class="form-control" v-model="steps[1].registration">
                </div>

                <div class="form-group">
                    <label class="form-label">Is the date and time visible in the image?</label>
                    <select class="form-control" style="width: 15rem;" v-model="steps[1].dateAndTime">
                        <option :value="null">Please select</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="form-label">Is the date and time out by more than 5 seconds?</label>
                    <select class="form-control" style="width: 15rem;" v-model="steps[1].outByFive">
                        <option :value="null">Please select</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>
                </div>

                <p v-if="steps[1].dateAndTime === false" class="tx-warning">If the date and time are not visible then you should cancel this parking charge by heading to the management tab.</p>

                <p v-if="steps[2].outByFive" class="tx-warning">If the date and time is out by more than 5 seconds then you should cancel this parking charge by heading to the management tab.</p>

                <p v-if="steps[1].registration !== claim.registration" class="tx-warning">The registration you have entered does not match the registration logged against this parking charge.</p>

                <button v-if="steps[1].dateAndTime === true && claim.registration === steps[1].registration && steps[1].outByFive === false" class="btn btn-sm btn-primary" @click="nextStep()">Next Step</button>

            </div>
        </div>

        <!-- STEP 3: Exit image -->
        <div class="row" v-if="currentStep === 2">
            <div :class="{'col-6': !pixelating, 'col-12': pixelating}">

                <div v-show="!pixelating">
                    <secure-image v-viewer :id="`event_` + exitEvent.id + '_vi'" v-if="exitEvent !== null && !exitEvent.grayscaled" :filename="exitEvent.image" :site_id="claim.site.id"  :endpoint="'/api/event/image/'" :storage_account="exitEvent.storage_account" style="width: 100%; display: block;"></secure-image>
                    <img v-else-if="exitEvent !== null && exitEvent.grayscaled" :id="`event_` + exitEvent.id + '_vi'" :src="exitEvent.grayscaled" style="width: 100%; display: block;" v-viewer />

                    <submit-button  :clicked="grayscaling" @click="grayscale(exitEvent)" text="Grayscale" background="btn btn-sm btn-warning" v-if="$can('claims-process')"></submit-button>
                    <button v-if="exitEvent != null && !pixelating" class="btn btn-sm btn-primary" @click="pixelateImage(exitEvent)">Pixelate</button>
                </div>
                
                <div v-show="pixelating">

                    <canvas :id="`event_` + exitEvent.id + '_canvas'" width="750" height="500"></canvas>

                    <div class="row">
                        <div class="col-12">
                            <button class="btn btn-sm btn-danger" style="margin-right: 10px;" @click="cancelPixelation">Cancel Edit</button>
                            <button class="btn btn-sm btn-primary" :disabled="addingRectangle" style="margin-right: 10px;" @click="addRectangle">Add Rectangle</button>
                            <button class="btn btn-sm btn-success" @click="pixelateAndSave(exitEvent)">Pixelate and Save</button>
                        </div>
                    </div>

                </div>

            </div>
            <div class="col-6">

                <p>Using the exit image to the left, complete the following checks.</p>

                <div class="form-group">
                    <label class="form-label">Vehicle Registration</label>
                    <input type="text" class="form-control" v-model="steps[2].registration">
                </div>

                <div class="form-group">
                    <label class="form-label">Is the date and time visible in the image?</label>
                    <select class="form-control" style="width: 15rem;" v-model="steps[2].dateAndTime">
                        <option :value="null">Please select</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="form-label">Is the date and time out by more than 5 seconds?</label>
                    <select class="form-control" style="width: 15rem;" v-model="steps[2].outByFive">
                        <option :value="null">Please select</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>
                </div>

                <p v-if="steps[2].dateAndTime === false" class="tx-warning">If the date and time are not visible then you should cancel this parking charge by heading to the management tab.</p>

                <p v-if="steps[2].outByFive" class="tx-warning">If the date and time is out by more than 5 seconds then you should cancel this parking charge by heading to the management tab.</p>

                <p v-if="steps[2].registration !== claim.registration" class="tx-warning">The registration you have entered does not match the registration logged against this parking charge.</p>

                <button v-if="steps[2].dateAndTime === true && claim.registration === steps[2].registration && steps[2].outByFive === false" class="btn btn-sm btn-primary" @click="nextStep()">Next Step</button>

            </div>
        </div>

        <!-- STEP 4: MMC Match -->
        <div class="row" v-if="currentStep === 3">
            <div class="col-6">

                <secure-image v-viewer v-if="entryEvent !== null" :filename="entryEvent.image" :site_id="claim.site.id"  :endpoint="'/api/event/image/'" :storage_account="entryEvent.storage_account" style="width: 100%; margin-bottom: 10px; display: block;"></secure-image>

                <secure-image v-viewer v-if="exitEvent !== null" :filename="exitEvent.image" :site_id="claim.site.id"  :endpoint="'/api/event/image/'" :storage_account="exitEvent.storage_account" style="width: 100%; display: block;"></secure-image>

            </div>
            <div class="col-6">

                <p>Using the entry and exit images to the left, complete the following checks.</p>

                <p>Try to use your best judgement when deciding if the make, model and colour match. If you aren't sure what the vehicle should look like we have provided some reference images below.</p>

                <p><strong>Expected Make:</strong> {{ claim.override_mmc == 1 ? claim.vehicle_make : claim.make }}</p>
                <p><strong>Expected Model:</strong> {{ claim.override_mmc == 1 ? claim.vehicle_model : claim.model }}</p>
                <p><strong>Expected Colour:</strong> {{ claim.override_mmc == 1 ? claim.vehicle_colour : claim.colour }}</p>

                <p>Adjust the year to update the example images.</p>

                <div class="form-group">

                    <label class="form-control-label">Year</label>
                    <select class="form-control form-control-dark" @change="updateSearchTerm" v-model="year">
                        <option value="2030">2030</option>
                        <option value="2029">2029</option>
                        <option value="2028">2028</option>
                        <option value="2027">2027</option>
                        <option value="2026">2026</option>
                        <option value="2025">2025</option>
                        <option value="2024">2024</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                        <option value="2007">2007</option>
                        <option value="2006">2006</option>
                        <option value="2005">2005</option>
                        <option value="2004">2004</option>
                        <option value="2003">2003</option>
                        <option value="2002">2002</option>
                        <option value="2001">2001</option>
                        <option value="2000">2000</option>
                        <option value="1999">1999</option>
                        <option value="1998">1998</option>
                        <option value="1997">1997</option>
                        <option value="1996">1996</option>
                        <option value="1995">1995</option>
                        <option value="1994">1994</option>
                        <option value="1993">1993</option>
                        <option value="1992">1992</option>
                        <option value="1991">1991</option>
                        <option value="1990">1990</option>
                        <option value="1989">1989</option>
                        <option value="1988">1988</option>
                        <option value="1987">1987</option>
                        <option value="1986">1986</option>
                        <option value="1985">1985</option>
                        <option value="1984">1984</option>
                        <option value="1983">1983</option>
                        <option value="1982">1982</option>
                        <option value="1981">1981</option>
                        <option value="1980">1980</option>
                        <option value="1979">1979</option>
                        <option value="1978">1978</option>
                        <option value="1977">1977</option>
                        <option value="1976">1976</option>
                        <option value="1975">1975</option>
                        <option value="1974">1974</option>
                        <option value="1973">1973</option>
                        <option value="1972">1972</option>
                        <option value="1971">1971</option>
                        <option value="1970">1970</option>
                        <option value="1969">1969</option>
                        <option value="1968">1968</option>
                        <option value="1967">1967</option>
                        <option value="1966">1966</option>
                        <option value="1965">1965</option>
                        <option value="1964">1964</option>
                        <option value="1963">1963</option>
                        <option value="1962">1962</option>
                        <option value="1961">1961</option>
                        <option value="1960">1960</option>
                        <option value="1959">1959</option>
                        <option value="1958">1958</option>
                        <option value="1957">1957</option>
                        <option value="1956">1956</option>
                        <option value="1955">1955</option>
                        <option value="1954">1954</option>
                        <option value="1953">1953</option>
                        <option value="1952">1952</option>
                        <option value="1951">1951</option>
                        <option value="1950">1950</option>
                        <option value="1949">1949</option>
                        <option value="1948">1948</option>
                        <option value="1947">1947</option>
                        <option value="1946">1946</option>
                        <option value="1945">1945</option>
                        <option value="1944">1944</option>
                        <option value="1943">1943</option>
                        <option value="1942">1942</option>
                        <option value="1941">1941</option>
                        <option value="1940">1940</option>
                        <option value="1939">1939</option>
                        <option value="1938">1938</option>
                        <option value="1937">1937</option>
                        <option value="1936">1936</option>
                        <option value="1935">1935</option>
                        <option value="1934">1934</option>
                        <option value="1933">1933</option>
                        <option value="1932">1932</option>
                        <option value="1931">1931</option>
                        <option value="1930">1930</option>
                        <option value="1929">1929</option>
                        <option value="1928">1928</option>
                        <option value="1927">1927</option>
                        <option value="1926">1926</option>
                        <option value="1925">1925</option>
                        <option value="1924">1924</option>
                        <option value="1923">1923</option>
                        <option value="1922">1922</option>
                        <option value="1921">1921</option>
                        <option value="1920">1920</option>
                        <option value="1919">1919</option>
                        <option value="1918">1918</option>
                        <option value="1917">1917</option>
                        <option value="1916">1916</option>
                        <option value="1915">1915</option>
                        <option value="1914">1914</option>
                        <option value="1913">1913</option>
                        <option value="1912">1912</option>
                        <option value="1911">1911</option>
                        <option value="1910">1910</option>
                        <option value="1909">1909</option>
                        <option value="1908">1908</option>
                        <option value="1907">1907</option>
                        <option value="1906">1906</option>
                        <option value="1905">1905</option>
                        <option value="1904">1904</option>
                        <option value="1903">1903</option>
                        <option value="1902">1902</option>
                        <option value="1901">1901</option>
                        <option value="1900">1900</option>
                    </select>

                </div>

                <div id="carousel1" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner" role="listbox">
                        <div v-for="(example, index) in exampleImages" :key="'img-' + example" class="carousel-item" :class="{'active': index == 0}">
                        <img style="width: 100%; height: auto;" :src="example">
                        </div>
                    </div><!-- carousel-inner -->
                    <a class="carousel-control-prev" href="#carousel1" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carousel1" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div><!-- carousel -->

                <div class="form-group">

                    <label class="form-control-label">Are you satisfied the make, model and colour match? <span class="text-danger">*</span></label>
                    <select class="form-control form-control-dark" v-model="steps[3].mmc_match">
                        <option :value="null">Please select</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>

                </div>

                <div v-if="steps[3].mmc_match === false">

                    <div class="form-group">
                        <label class="form-control-label">Make</label>
                        <input type="text" class="form-control" v-model="steps[3].make">
                    </div>

                    <div class="form-group">
                        <label class="form-control-label">Model</label>
                        <input type="text" class="form-control" v-model="steps[3].model">
                    </div>

                    <div class="form-group">
                        <label class="form-control-label">Colour</label>
                        <input type="text" class="form-control" v-model="steps[3].colour">
                    </div>

                    <button class="btn btn-sm btn-warning" @click="updateMmc">Override Make, Model and Colour</button>

                </div>

                <button v-if="steps[3].mmc_match === true" class="btn btn-sm btn-primary" @click="nextStep">Next Step</button>

            </div>
        </div>

        <!-- STEP 5: Customer parking -->
        <div class="row" v-if="currentStep === 4">
            <div class="col-6">

                <p> Please review the entries from the customer parking tablets below. </p>

                <p style="font-weight: bold">Minor Keying</p>

                <table class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                        <tr>
                            <th>REGISTRATION</th>
                            <th>SITE</th>
                            <th>ADDED</th>
                            <th>EXPIRY</th>
                            <th>SOURCE</th>
                            <th>DISTANCE</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in minorKeyingError">
                            <td>{{entry.plate}}</td>
                            <td>{{entry.name}}</td>
                            <td>{{entry.added}}</td>
                            <td>{{entry.expiry}}</td>
                            <td>{{entry.source}}</td>
                            <td>{{entry.distance}}</td>
                        </tr>
                        <tr v-if="minorKeyingError.length < 1">
                            <td colspan="6" class="text-center">No minor keying errors detected</td>
                        </tr>
                    </tbody>
                </table>

                <p style="font-weight: bold">Major Keying</p>

                <table class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                        <tr>
                            <th>REGISTRATION</th>
                            <th>SITE</th>
                            <th>ADDED</th>
                            <th>EXPIRY</th>
                            <th>SOURCE</th>
                            <th>DISTANCE</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in majorKeyingError">
                            <td>{{entry.plate}}</td>
                            <td>{{entry.name}}</td>
                            <td>{{entry.added}}</td>
                            <td>{{entry.expiry}}</td>
                            <td>{{entry.source}}</td>
                            <td>{{entry.distance}}</td>
                        </tr>
                        <tr v-if="majorKeyingError.length < 1">
                            <td colspan="6" class="text-center">No major keying errors detected</td>
                        </tr>
                    </tbody>
                </table>

                <p style="font-weight: bold;">All Entries</p>

                <!-- Pagination -->
                <div class="row">
                    <div class="col-12">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item" :class="{'disabled': customerParkingPage === 1}">
                                    <a class="page-link" @click="customerParkingPage--">Prev</a>
                                </li>
                                <li class="page-item" v-for="page in customerParkingPages" :key="page" :class="{'active': page === customerParkingPage}">
                                    <a class="page-link" @click="customerParkingPage = page">{{page}}</a>
                                </li>
                                <li class="page-item" :class="{'disabled': customerParkingPage === customerParkingPages}">
                                    <a class="page-link" @click="customerParkingPage++">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <table class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                        <tr>
                            <th>REGISTRATION</th>
                            <th>SITE</th>
                            <th>ADDED</th>
                            <th>EXPIRY</th>
                            <th>SOURCE</th>
                            <th>DISTANCE</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in customerParkingPageData">
                            <td>{{entry.plate}}</td>
                            <td>{{entry.name}}</td>
                            <td>{{entry.added}}</td>
                            <td>{{entry.expiry}}</td>
                            <td>{{entry.source}}</td>
                            <td>{{entry.distance}}</td>
                        </tr>
                        <tr v-if="customerParkingPageData.length < 1">
                            <td colspan="6" class="text-center">No entries detected</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Pagination -->
                <div class="row">
                    <div class="col-12">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item" :class="{'disabled': customerParkingPage === 1}">
                                    <a class="page-link" @click="customerParkingPage--">Prev</a>
                                </li>
                                <li class="page-item" v-for="page in customerParkingPages" :key="page" :class="{'active': page === customerParkingPage}">
                                    <a class="page-link" @click="customerParkingPage = page">{{page}}</a>
                                </li>
                                <li class="page-item" :class="{'disabled': customerParkingPage === customerParkingPages}">
                                    <a class="page-link" @click="customerParkingPage++">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
            <div class="col-6">
                    
                <p v-if="minorKeyingError.length > 0" class="tx-danger">A minor keying error has been detected. Please cancel the claim from the management tab.</p>

                <p v-if="majorKeyingError.length > 0" class="tx-warning">A major keying error has been detected. You may continue to process the charge.</p>

                <button v-if="minorKeyingError.length < 1" class="btn btn-sm btn-primary" @click="nextStep">Next Step</button>
    
                <TabletUptime v-for="tablet in claim.parking_tablets" :tablet="tablet" :key="tablet.id" :start="uptimeStart" :end="uptimeEnd"></TabletUptime>

            </div>
        </div>

        <!-- STEP 6: Events -->
        <div class="row" v-if="currentStep === 5">

            <div class="col-6">
                <table v-show="!loading" width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>#</th>
                        <th>PHOTO</th>
                        <th>STATUS</th>
                        <th>PLATE</th>
                        <th>I/O</th>
                        <th>DATETIME</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="e in events" :key="`${claim.id}_${e.id}`" v-if="!loading" style="cursor: pointer">
                        <td>{{e.id}}</td>
                        <td>
                            <secure-image v-viewer v-if="e.image !== null && !e.grayscaled" style="width: 75px; height: auto;" :filename="e.image" :site_id="claim.site_id" :storage_account="e.storage_account"></secure-image>
                            <img v-else-if="e.image !== null && e.grayscaled" :src="e.grayscaled" style="width: 75px; height: auto;" v-viewer />
                            <span v-else>Image Deleted</span>
                        </td>
                        <td v-if="e.status === 'blacklisted'">
                            Blacklisted <span v-if="e.national_blacklist">(On National Blacklist)</span>
                        </td>
                        <td v-if="e.status !== 'blacklisted'">
                            Active <span v-if="e.national_blacklist">(On National Blacklist)</span>
                        </td>
                        <td>{{e.vehicle.plate}}</td>
                        <td>{{e.io}}</td>
                        <td>{{e.created_at}}</td>
                    </tr>
                    <tr v-if="events.length < 1 && !loading">
                        <td colspan="6" class="text-center">No Events</td>
                    </tr>
                    </tbody>
                </table>
                <pagination :data="pagination" @search="loadEvents"></pagination>
            </div>
            <div class="col-6">

                <p>Please review the event history to the left and if you're happy, continue to the next step.</p>

                <button class="btn btn-sm btn-primary" @click="nextStep">Next Step</button>

            </div>

        </div>


        <!-- STEP 7: Checklist -->
        <div class="row" v-if="currentStep === 6">
            <div class="col-6">
                <secure-image v-viewer v-if="entryEvent !== null" :filename="entryEvent.image" :site_id="claim.site.id"  :endpoint="'/api/event/image/'" :storage_account="entryEvent.storage_account" style="width: 100%; margin-bottom: 10px; display: block;"></secure-image>
            </div>
            <div class="col-6">
                <secure-image v-viewer v-if="exitEvent !== null" :filename="exitEvent.image" :site_id="claim.site.id"  :endpoint="'/api/event/image/'" :storage_account="exitEvent.storage_account" style="width: 100%; display: block;"></secure-image>
            </div>
            <div class="col-12">

                <div class="form-group">
                    <label class="form-label">Are you sure?</label>
                    <select class="form-control" style="width: 15rem;" v-model="steps[6].areYouSure">
                        <option :value="null">Please select</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>
                </div>

                <div v-if="kadoeErrors.length > 0">

                    <p>The following errors were encountered when submitting the enquiry:</p>
                    <ul>
                        <li v-for="error in kadoeErrors">
                            {{error.code}} - {{ error.detail }}
                        </li>
                    </ul>

                </div>

                <button v-if="steps[6].areYouSure === true" class="btn btn-sm btn-primary" @click="updateStatus()">Send to KADOE</button>

            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import SecureImage from '@/components/SecureImage.vue';
import {DateTime} from 'luxon';
import Pagination from '@/components/Pagination.vue';
import SubmitButton from '../../../../../components/SubmitButton.vue';
import "jimp";
import TabletUptime from '../../../../../components/TabletUptime.vue';

export default {
    name: "ParkingPending",
    props: {
        claim: Object,
        client_id: Number | String
    },
    components: {
        SecureImage,
        Pagination,
        SubmitButton,
        TabletUptime
    },
    data(){
        return {
            currentStep: 0,
            steps: [
                {
                    overstay: null
                },
                {
                    registration: null,
                    dateAndTime: null,
                    outByFive: null
                },
                {
                    registration: null,
                    dateAndTime: null,
                    outByFive: null
                },
                {
                    mmc_match: null,
                    updated: false,
                    make: null,
                    model: null,
                    colour: null
                },
                {

                },
                {

                },
                {
                    areYouSure: null
                }
            ],
            searchTerm: '',
            year: null,
            exampleImages: [],
            customerParking: [],
            customerParkingPage: 1,
            events: [],
            pagination: {
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 0,
                lastPage: 1,
            },
            grayscaling: false,
            objectUrls: [],
            pixelating: false,
            rectangles: [],
            canvas: null,
            addingRectangle: false,
            isDown: false,
            origX: null,
            origY: null,
            drawRect: null,
            widthAspectRatio: 1,
            heightAspectRatio: 1,
            finalHeight: 0,
            finalWidth: 0,
            kadoeErrors: []
        }
    },
    beforeUnmount(){
        this.objectUrls.forEach((url) => {
            URL.revokeObjectURL(url);
        });
    },
    mounted(){
        this.searchTerm = `${this.claim.make || ''} ${this.claim.model || ''} ${this.claim.colour || ''}`;
        if(this.claim.override_mmc == 1){
            this.searchTerm = `${this.claim.vehicle_make || ''} ${this.claim.vehicle_model || ''} ${this.claim.vehicle_colour || ''}`;
        }
        this.loadCustomerParking();
        this.loadEvents();
    },
    methods: {
        nextStep(){
            this.currentStep++;
            if(this.currentStep === 3){
                this.updateSearchTerm();
            }
        },
        updateStatus(){
            this.kadoeErrors = [];
            axios.post(`https://api.varsanpr.com/api/claims/${this.claim.id}/kadoe`, {
                client_id: this.client_id,
                overstay: this.steps[0].overstay,
                registration: this.steps[1].registration,
                dateAndTime: this.steps[1].dateAndTime,
                outByFive: this.steps[1].outByFive,
                registrationTwo: this.steps[2].registration,
                dateAndTimeTwo: this.steps[2].dateAndTime,
                outByFiveTwo: this.steps[2].outByFive,
                mmc_match: this.steps[3].mmc_match,
                updated: this.steps[3].updated,
            }, {
                headers: authHeader()
            })
                .then(response => {

                    if(response.data.cancelled === true){
                        this.$emit('setstatus', 'Cancelled')
                        this.$emit('changeMade');
                        this.$emit('reloadClaim', {});
                        this.$success("Claim automatically cancelled due to no keeper details error");
                        return;
                    }

                    if(response.data.clerical === true){
                        this.$emit('setstatus', 'Clerical Records');
                        this.$emit('changeMade');
                        this.$emit('reloadClaim', {});
                        this.$success("Claim set to clerical records");
                        return;
                    }

                    this.$emit('setstatus', 'At DVLA')
                    this.$emit('changeMade');
                    this.$emit('reloadClaim', {});
                    this.$success("Successfully updated claim status")
                })
                .catch(error => {
                    this.$error("Failed to update claim status.", error);

                    if(error.response?.data?.errors){
                        this.kadoeErrors = error.response.data.errors;
                    }

                    if(error.response?.data?.message){
                        this.kadoeErrors = [{
                            code: "",
                            message: error.response.data.message
                        }];
                    }

                });
        },
        updateSearchTerm(){
            this.searchTerm = `${this.claim.make || ''} ${this.claim.model || ''} ${this.claim.colour || ''} ${this.year || ''}`;
            if(this.claim.override_mmc == 1){
                this.searchTerm = `${this.claim.vehicle_make || ''} ${this.claim.vehicle_model || ''} ${this.claim.vehicle_colour || ''}`;
            }
            this.loadExampleImages();
        },
        loadExampleImages(){
            console.log(`Searching for: ${this.searchTerm}`);
            axios.get(`https://api.varsanpr.com/api/misc/imagesearch?search=${this.searchTerm}`, {
                headers: authHeader()
            })
            .then((response) => {
                this.exampleImages = response.data.images;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        loadCustomerParking(){
            axios.get(`https://api.varsanpr.com/api/parking?site_id=${this.claim.site_id}&start_datetime=${this.claim.events[0].created_at - 28800}&end_datetime=${this.claim.events[1].created_at + 28800}&page=1&count=1000`, {
                headers: authHeader()
            })
            .then(response => {
                response.data.events.forEach((event) => {
                    event.added_seconds = event.added;
                    event.added = DateTime.fromSeconds(event.added).toFormat('dd/MM/yyyy HH:mm');
                    event.expiry = DateTime.fromSeconds(event.expiry).toFormat('dd/MM/yyyy HH:mm');
                    event.distance = this.levenshteinDistance(event.plate, this.claim.vehicle.plate);
                })
                // sort results by the closest match to the claimant's vehicle
                response.data.events.sort((a, b) => {
                    return b.added_seconds - a.added_seconds;
                });

                this.customerParking = response.data.events;

            });
        },
        levenshteinDistance(a, b){
        if(a.length === 0) return b.length;
        if(b.length === 0) return a.length;
      
        let matrix = [];
      
        // increment along the first column of each row
        let i;
        for(i = 0; i <= b.length; i++){
          matrix[i] = [i];
        }
      
        // increment each column in the first row
        let j;
        for(j = 0; j <= a.length; j++){
          matrix[0][j] = j;
        }
      
        // Fill in the rest of the matrix
        for(i = 1; i <= b.length; i++){
          for(j = 1; j <= a.length; j++){
            if(b.charAt(i-1) === a.charAt(j-1)){
              matrix[i][j] = matrix[i-1][j-1];
            }else{
              matrix[i][j] = Math.min(
                matrix[i-1][j-1] + 1, // substitution
                Math.min(
                  matrix[i][j-1] + 1, // insertion
                  matrix[i-1][j] + 1 // deletion
                )
              );
            }
          }
        }
      
        return matrix[b.length][a.length];
      },
      updateMmc(){
        axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/mmc`, {
            make: this.steps[3].make,
            model: this.steps[3].model,
            colour: this.steps[3].colour,
            fuelType: this.claim.fuelType,
            client_id: this.client_id
        }, {
            headers: authHeader()
        })
            .then(response => {
                this.$emit('changeMade');
                this.emitter.emit(`claim_${this.claim.id}_mmc_update`, {make: this.steps[3].make, model: this.steps[3].model, colour: this.steps[3].colour, fuelType: this.claim.fuelType});
                this.$success("Changed Make, Model and Colour");
                this.steps[3].mmc_match = true;
                this.steps[3].updated = true;
            })
            .catch(error => {
                this.$error("Failed to update Make, Model and Colour", error);
            })
      },
      loadEvents(page = 1){
        axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/events`, {
                headers: authHeader(),
                params: {
                    client_id: this.client_id,
                    count: 25,
                    page: page,
                    site_id: this.claim.site_id,
                    registration: this.claim.vehicle.plate,

                }
            })
                .then(response => {
                    response.data.events.forEach((event) => {
                        event.created_at = DateTime.fromSeconds(parseInt(event.created_at)).toFormat('dd/MM/yyyy HH:mm:ss');
                        event.io = "";
                        if(event.direction === 'forward'){
                            event.io = 'In';
                        }
                        if(event.direction === 'reverse'){
                            event.io = 'Out';
                        }
                        event.evidence = this.claim.events.filter((e) => {
                            return e.id == event.id;
                        }).length > 0;
                    });
                    this.events = response.data.events;
                    this.pagination.currPage = response.data.pagination.page;
                    this.pagination.totalResults = response.data.pagination.total;
                    this.pagination.from = 1 + (response.data.pagination.page -1) * response.data.pagination.showing;
                    this.pagination.to = 1 + response.data.pagination.page * response.data.pagination.showing;
                    if(this.pagination.to > this.pagination.totalResults){
                        this.pagination.to = this.pagination.totalResults;
                    }
                    this.pagination.perPage = response.data.pagination.showing;
                    this.pagination.lastPage = response.data.pagination.lastPage;
                })
                .catch(error => {
                    this.$error("Failed to load events", error);
                });
      },
      grayscale(event){
            if(this.grayscaling) return;
            this.grayscaling = true;
            axios.post(`https://api.varsanpr.com/api/event/${event.id}/grayscale`, {
                client_id: this.client_id
            }, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
            .then(response => {
                let blob = new Blob([response.data], {type: 'application/pdf'});
                event.grayscaled = URL.createObjectURL(blob);
                this.objectUrls.push(event.grayscaled);
            })
            .catch(error => {
                this.$error("Failed to grayscale image", error);
            })
            .finally(() => {
                this.grayscaling = false;
            });
        },

        // Pixelate

        pixelateImage(event){
            this.pixelating = true;
            this.rectangles = [];
            if(this.canvas){
                this.canvas.clear();
                this.canvas = null;
            }
            this.rectangles = [];
            this.addingRectangle = false;
            this.origX = null;
            this.origY = null;
            this.drawRect = null;
            this.isDown = false;
            this.widthAspectRatio = 1;
            this.heightAspectRatio = 1;
            this.finalHeight = 0;
            this.finalWidth = 0;

            if(!this.canvas){
                console.log(`event_` + event.id + '_canvas');
                this.canvas = new fabric.Canvas(`event_` + event.id + '_canvas');
            }

            let selectedImage;
            if(event.image !== null){
                selectedImage = document.getElementById('event_' + event.id + '_vi');
            }else{
                return;
            }

            let img = new Image();
            img.onload = () => {
                let maxWidth = 750;
                let maxHeight = 500;

                let imgWidth = img.width;
                let imgHeight = img.height;

                console.log(`Image Dimensions: `, imgWidth, imgHeight);

                let widthAspectRatio = maxWidth / imgWidth;
                let heightAspectRatio = maxHeight / imgHeight;

                console.log(`Aspect Ratios: `, widthAspectRatio, heightAspectRatio);

                this.widthAspectRatio = widthAspectRatio;
                this.heightAspectRatio = heightAspectRatio;

                let finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

                let finalHeight = imgHeight * finalAspectRatio;
                let finalWidth = imgWidth * finalAspectRatio;

                this.finalHeight = finalHeight;
                this.finalWidth = finalWidth;

                let imgTop = 0;

                let imgLeft = 0;

                let nsgImage = new fabric.Image(img).scale(finalAspectRatio);
                nsgImage.set({left: imgLeft, top: imgTop});

                this.canvas.setBackgroundImage(nsgImage, () => this.canvas.renderAll());

                this.canvas.setWidth(finalWidth);
                this.canvas.setHeight(finalHeight);
                console.log(`Image load finished`);
            }
            img.src = selectedImage.src;
        },
        addRectangle(){
            if(this.addingRectangle){
                this.addingRectangle = false;
                return;
            }
            this.addingRectangle = true;
            this.canvas.on('mouse:down', (o) => {
                this.isDown = true;
                var pointer = this.canvas.getPointer(o.e);
                this.origX = pointer.x;
                this.origY = pointer.y;
                var pointer = this.canvas.getPointer(o.e);
                this.drawRect = new fabric.Rect({
                    left: this.origX,
                    top: this.origY,
                    originX: 'left',
                    originY: 'top',
                    width: pointer.x-this.origX,
                    height: pointer.y-this.origY,
                    angle: 0,
                    fill: 'rgba(0,0,0,0.2)',
                    transparentCorners: false
                });
                this.rectangles.push(this.drawRect);
                this.canvas.add(this.drawRect);
            });

            this.canvas.on('mouse:move', (o) => {
                if(!this.isDown) return;
                var pointer = this.canvas.getPointer(o.e);

                if(this.origX>pointer.x){
                    this.drawRect.set({ left: Math.abs(pointer.x) });
                }
                if(this.origY>pointer.y){
                    this.drawRect.set({ top: Math.abs(pointer.y) });
                }

                this.drawRect.set({ width: Math.abs(this.origX - pointer.x) });
                this.drawRect.set({ height: Math.abs(this.origY - pointer.y) });

                this.canvas.renderAll();
            });

            this.canvas.on('mouse:up', (o) => {
                this.isDown = false;
                this.canvas.off('mouse:down');
                this.canvas.off('mouse:move');
                this.canvas.off('mouse:up');
                this.addingRectangle = false;

                console.log(this.rectangles);
            })
        },
        async pixelateAndSave(event){
            // Using the rectangles stored in this.rectangles, use jimp to apply a pixelation to those sections of the image
            // Then save the image

            let selectedImage;
            if(event.image !== null){
                selectedImage = document.getElementById('event_' + event.id + '_vi');
            }else{
                return;
            }

            await Jimp.read(selectedImage.src)
                .then(async (jimpImage) => {

                    // Check aspect ratio
                    console.log(jimpImage.bitmap.width, jimpImage.bitmap.height);
                    let widthAspectRatio = jimpImage.bitmap.width / this.finalWidth;
                    let heightAspectRatio = jimpImage.bitmap.height / this.finalHeight;
                    console.log(widthAspectRatio, heightAspectRatio);

                    for await (let rect of this.rectangles){

                        let x = rect.left * widthAspectRatio;
                        let y = rect.top * heightAspectRatio;
                        let width = rect.width * widthAspectRatio;
                        let height = rect.height * heightAspectRatio;
                        // width = width * 2
                        console.log(`Aspect Ratio:`, widthAspectRatio, heightAspectRatio);

                        console.log(`Rect: `, rect.left, rect.top, rect.width, rect.height);
                        console.log(`Final: `, x, y, width, height);

                        await jimpImage.pixelate(
                            50,
                            x,
                            y,
                            width,
                            height
                        );
                    }

                    jimpImage.getBuffer(Jimp.MIME_JPEG, (err, buffer) => {
                        if(err){
                            console.error(err);
                            return;
                        }

                        let blob = new Blob([buffer], {type: 'image/jpeg'});

                        // TODO: Need an endpoint to accept an updated event image

                        let formData = new FormData();
                        formData.append("file", blob);
                        axios.put(`https://api.varsanpr.com/api/event/${event.id}/image`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                ...authHeader(),
                            },
                            params: {
                                client_id: this.client_id
                            },
                            responseType: 'arraybuffer'
                        })
                        .then(response => {
                            
                            let blob = new Blob([response.data], {type: 'application/pdf'});
                            event.grayscaled = URL.createObjectURL(blob);
                            this.objectUrls.push(event.grayscaled);

                            this.$success("Successfully uploaded event image.");

                        })
                        .catch(error => {
                            this.$error("Failed to upload evidence file.", error);
                        })
                        .finally(() => {
                            // this.addEvidence.uploading = false;
                            this.canvas.clear();
                            this.rectangles = [];
                            this.addingRectangle = false;
                            this.origX = null;
                            this.origY = null;
                            this.drawRect = null;
                            this.isDown = false;
                            this.widthAspectRatio = 1;
                            this.heightAspectRatio = 1;
                            this.finalHeight = 0;
                            this.finalWidth = 0;
                            this.pixelating = false;
                        })

                    });

                })
                .catch(err => {
                    console.error(err);
                })
        },
        cancelPixelation(){
            this.pixelating = false;
        }

    },
    // TODO: Pixelate option for event images (works like the grayscaling option)
    computed: {
        entryEvent: function(){
            return this.claim.events[0];
        },
        exitEvent: function(){
            return this.claim.events[1];
        },
        minorKeyingError: function(){
            return this.customerParking.filter((event) => {
                return event.distance < 2;
            });
        },
        majorKeyingError: function(){
            return this.customerParking.filter((event) => {
                return event.distance === 2;
            });
        },
        customerParkingPages: function(){
            return Math.ceil(this.customerParking.length / 10);
        },
        customerParkingPageData: function(){
            return this.customerParking.slice((this.customerParkingPage - 1) * 10, this.customerParkingPage * 10);
        },
        uptimeStart: function(){
            // 1 hour before the first event
            return DateTime.fromSeconds(this.claim.events[0].created_at - 3600).toISO();
        },
        uptimeEnd: function(){
            // 1 hour after the last event
            return DateTime.fromSeconds(this.claim.events[1].created_at + 3600).toISO();
        }
    }
}
</script>